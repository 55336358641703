import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';

import { getPortalSignUpStudentUrl } from '../../utils/urls';

const useStyles = makeStyles({
  buttonContainer: {
    display: 'inline-block',
    '& button': {
      width: '100%',
    },
  },
  content: {
    marginTop: 0,
  },
  dialog: {
    textAlign: 'center',
  },
});

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onTeacherSignUpClick: () => void;
}

const SignUpDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  onTeacherSignUpClick,
}: Props) => {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={onClose} className={classes.dialog}>
      <DialogTitle disableTypography>
        <Typography variant="h4">Sign up for an account</Typography>
      </DialogTitle>
      <Box my={2}>
        <Divider variant="middle" />
      </Box>
      <DialogContent className={classes.content}>
        <Typography variant="body1">Which best describes your role?</Typography>
        <Box className={classes.buttonContainer}>
          <Box my={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onTeacherSignUpClick}
            >
              SCHOOL STAFF
            </Button>
          </Box>
          <Box mb={2}>
            <a href={getPortalSignUpStudentUrl()}>
              <Button variant="contained" color="secondary">
                STUDENT
              </Button>
            </a>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SignUpDialog;
