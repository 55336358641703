export const DOMAIN = 'findingfocus.app';

export enum BUILD_TYPE {
  BETA = 'beta',
  PRODUCTION = 'production',
  LOCAL = 'local',
}

export const getDomain = (): BUILD_TYPE => {
  if (process.env.GATSBY_BUILD_ENV === BUILD_TYPE.BETA) {
    return BUILD_TYPE.BETA;
  } else if (process.env.GATSBY_BUILD_ENV === BUILD_TYPE.LOCAL) {
    return BUILD_TYPE.LOCAL;
  }
  return BUILD_TYPE.PRODUCTION;
};

export const getPortalUrl = (path = ''): string => {
  const buildType = getDomain();
  switch (buildType) {
    case BUILD_TYPE.BETA:
      return `https://beta-portal.${DOMAIN}${path}`;
    case BUILD_TYPE.LOCAL:
      return `http://localhost:3001${path}`;
    default:
      return `https://portal.${DOMAIN}${path}`;
  }
};

export const getPortalSignInUrl = (): string => getPortalUrl('/signIn');
export const getPortalSignUpUrl = (): string =>
  getPortalUrl('/marketingRequestAnAccount?showSignUpDialogRedirect=true');
export const getPortalStudentSignInUrl = (): string =>
  getPortalUrl('/student/sign-in');
export const getPortalStudentForgotPasswordUrl = (): string =>
  getPortalUrl('/student/forgotPassword');
export const getPortalSignUpStudentUrl = (): string =>
  getPortalUrl('/marketingRequestAnAccount?showSignUpDialogRedirect=true');
