import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from 'gatsby';
import React from 'react';

import { Brain } from '../assets';
import {
  getPortalSignInUrl,
  getPortalSignUpStudentUrl,
  getPortalUrl,
} from '../utils/urls';
import useSignUp from './signUpDialog/signUpHooks';

const StyledButton = withStyles({
  root: {
    transition: 'none',
    textTransform: 'none',
    padding: 0,
    fontWeight: 400,
    fontSize: 14,
    paddingBottom: 10,
    color: '#444444',
    '&:hover': {
      backgroundColor: 'inherit',
      textDecoration: 'underline',
    },
    '&:focus': {
      backgroundColor: 'inherit',
      textDecoration: 'underline',
    },
  },
})(Button);

const useStyles = makeStyles(theme => ({
  footer: {
    padding: 40,
  },
  group: {
    paddingTop: 80,
  },
  linkList: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 20,
    },
    '& button': {
      textAlign: 'left',
    },
    '& p': {
      fontWeight: 600,
      textAlign: 'left',
      fontSize: 16,
      paddingBottom: 3,
    },
    '& a': {
      fontWeight: 400,
      textAlign: 'left',
      fontSize: 14,
      display: 'block',
      paddingBottom: 10,
      color: '#444444',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
      '&:focus': {
        textDecoration: 'underline',
      },
    },
  },
  brainLogo: {
    width: 175,
    height: 169,
  },
}));

interface Props {
  onTeacherSignUpClick: () => void;
}

const Footer: React.FC = () => {
  const { showSignUp } = useSignUp();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const classes = useStyles();
  return (
    <footer>
      <Grid
        container
        justify={'space-between'}
        direction={'row'}
        className={[classes.group, classes.footer].join(' ')}
      >
        {isLargeScreen && (
          <Grid item xs={3} sm={3} md={2} className={classes.linkList}>
            <Brain className={classes.brainLogo} />
          </Grid>
        )}
        <Grid item xs={5} sm={3} md={2} className={classes.linkList}>
          <Typography variant={'body2'} gutterBottom>
            Quick Links
          </Typography>
          <a href={getPortalSignInUrl()}>Log in</a>
          <Link to="/for-educators">For educators</Link>
          <a href={getPortalSignUpStudentUrl()}>Student sign up</a>
          <StyledButton
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              window.location = getPortalUrl(
                '/marketingRequestAnAccount?v2=true&showRequestTeacherAccountDialogRedirect=true'
              );
            }}
            disableRipple={true}
            disableFocusRipple={true}
            disableTouchRipple={true}
          >
            Teacher sign up
          </StyledButton>
        </Grid>
        <Grid item xs={5} sm={3} md={2} className={classes.linkList}>
          <Typography variant={'body2'} gutterBottom>
            Mobile Apps
          </Typography>
          <a
            href={
              'https://itunes.apple.com/us/app/evidence-based-courses/id1387307192?mt=8'
            }
            target={'_blank'}
            rel="noopener noreferrer"
            aria-label={'Download iOS App Link'}
          >
            iOS app
          </a>
          <a
            href={
              'https://play.google.com/store/apps/details?id=com.evidencebasedcourses'
            }
            target={'_blank'}
            rel="noopener noreferrer"
            aria-label={'Download Android App Link'}
          >
            Android app
          </a>
        </Grid>
        <Grid item xs={5} sm={3} md={2} className={classes.linkList}>
          <Typography variant={'body2'} gutterBottom>
            Help
          </Typography>
          <a
            href={'https://findingfocus.zendesk.com/hc/en-us'}
            target={'_blank'}
            rel="noopener noreferrer"
          >
            Help center
          </a>
          <a
            href={'https://findingfocus.zendesk.com/hc/en-us/requests/new'}
            target={'_blank'}
            rel="noopener noreferrer"
          >
            Contact us
          </a>
          <Link to="/siteMap">Site map</Link>
        </Grid>
        <Grid item xs={5} sm={3} md={2} className={classes.linkList}>
          <Typography variant={'body2'} gutterBottom>
            Legal
          </Typography>
          <Link to="/privacy">Privacy policy</Link>
          <Link to="/terms">Terms of use</Link>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
