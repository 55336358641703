import React from 'react';

export interface ContextProps {
  showSignUp: () => void;
  hideSignUp: () => void;
  isSignUpOpen: boolean;
}

export const SignUpContext = React.createContext<ContextProps>({
  showSignUp: () => null,
  hideSignUp: () => null,
  isSignUpOpen: false,
});
