import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  content: {
    marginTop: 0,
  },
});

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const SignUpTeacherDialog: React.FC<Props> = ({ isOpen, onClose }: Props) => {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle disableTypography>
        <Box textAlign="center">
          <Typography variant="h4" gutterBottom>
            Request a teacher account
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography>
          Thanks for requesting your account. We’ll send you an invitation email
          soon, usually within one business day. The email will come from
          support@findingfocus.app. Check your spam or other filter folders if
          you don’t see it within one business day.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
          variant="contained"
          autoFocus={true}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignUpTeacherDialog;
