export { default as brain } from './images/brain.png';
export { default as brain2x } from './images/brain@2x.png';
export { default as iPhone131x } from './images/iPhone13@1x.png';
export { default as iPhone132x } from './images/iPhone13@2x.png';
export { default as hexMusic } from './images/hex_music.png';
export { default as hexMusic2x } from './images/hex_music@2x.png';
export { default as hexLineChart } from './images/hex_line_chart.png';
export { default as hexLineChart2x } from './images/hex_line_chart@2x.png';
export { default as hexProfile } from './images/hex_profile.png';
export { default as hexProfile2x } from './images/hex_profile@2x.png';
export { default as MacbookPro1x } from './images/macbook_pro_retina@1x.png';
export { default as MacbookPro2x } from './images/macbook_pro_retina@2x.png';
export { default as MatrixBackground } from './images/matrix_background.png';
export { default as RadialGradient } from './images/radial_gradient.png';
export { default as DepressionGraph } from './images/teenage_depression_graph.png';
export { default as ScientificPapers } from './images/scientific_papers.png';

export { default as FacebookIcon } from './svg/Facebook.svg';
export { default as InstagramIcon } from './svg/Instagram.svg';
export { default as SnapchatIcon } from './svg/Snapchat.svg';
export { default as TikTokIcon } from './svg/TikTok.svg';
export { default as TumblrIcon } from './svg/Tumblr.svg';
export { default as TwitterIcon } from './svg/Twitter.svg';
export { default as YoutubeIcon } from './svg/Youtube.svg';
export { default as GraphImg } from './svg/Graph.svg';
export { default as Brain } from './svg/brain.svg';
export { default as AttentionIcon } from './svg/Attention.svg';
export { default as ThoughtsIcon } from './svg/Thoughts.svg';
export { default as EmotionsIcon } from './svg/Emotions.svg';
export { default as RightArrow } from './svg/Right_Arrow.svg';
