import { makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(theme => ({
  link: {
    fontWeight: 500,
    textUnderlineOffset: '7px',
    transition: 'opacity 0.2s, color 0.2s ease-in-out',
    '&:hover': {
      opacity: 0.6,
    },
  },
}));

interface Props {
  linkPath: string;
  linkText: string;
  isScrolled?: boolean;
}

const InternalNavLink: React.FC<Props> = ({
  linkPath,
  linkText,
  isScrolled,
}: Props) => {
  const classes = useStyles();
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    if (location.pathname === linkPath) {
      setIsActive(true);
    }
  }, []);
  return (
    <Link
      to={linkPath}
      className={classes.link}
      style={{
        color: isScrolled ? '#000' : '#fff',
        textDecoration: isActive ? 'underline' : 'none',
      }}
    >
      {linkText}
    </Link>
  );
};

export default InternalNavLink;
