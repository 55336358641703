import '../styles/global.css';

import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useState } from 'react';
import { Sticky, StickyContainer } from 'react-sticky';

import theme from '../root/theme';
import AppBar from './appBar';
import Footer from './footer';
import SignUpDialog from './signUpDialog/signUpDialog';
import SignUpProvider from './signUpDialog/signUpProvider';

interface Props {
  children: React.ReactNode;
  isHeaderSticky?: boolean;
}

const Layout: React.FC<Props> = ({
  children,
  isHeaderSticky = true,
}: Props) => {
  const [displayContentForFOUC, setDisplayContentForFOUC] = useState(false);
  // On page load, display content to prevent flash
  const displayContentCallback = () => setDisplayContentForFOUC(true);

  useEffect(() => {
    // document.addEventListener('DOMContentLoaded', displayContentCallback)
    document.readyState === 'interactive' || document.readyState === 'complete'
      ? displayContentCallback()
      : document.addEventListener('DOMContentLoaded', () => {
          console.log('loaded');
        });
    return () => {
      document.removeEventListener('DOMContentLoaded', displayContentCallback);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <SignUpProvider>
        <CssBaseline />
        <div
          style={{
            visibility: displayContentForFOUC ? 'visible' : 'hidden',
          }}
        >
          <StickyContainer>
            <Sticky disableCompensation={true}>
              {(props): JSX.Element => (
                <div>
                  <AppBar {...props} isSticky={isHeaderSticky} />
                </div>
              )}
            </Sticky>
            <main> {children} </main>
            <Footer />
          </StickyContainer>
        </div>
        <SignUpDialog />
      </SignUpProvider>
    </ThemeProvider>
  );
};

export default Layout;
