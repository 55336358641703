import React from 'react';

import SignUpDialogChoose from './signUpDialogChoose';
import useSignUp from './signUpHooks';
import SignUpTeacherDialog from './signUpTeacherDialog';

const SignUpDialog: React.FC = () => {
  const { isSignUpOpen, hideSignUp } = useSignUp();
  const [showTeacherSignUpModal, setShowTeacherSignUpModal] = React.useState(
    false
  );
  const onTeacherSignUpClick = (): void => {
    setShowTeacherSignUpModal(true);
  };

  if (isSignUpOpen && showTeacherSignUpModal) {
    const onTeacherDialogClose = (): void => {
      setShowTeacherSignUpModal(false);
      hideSignUp();
    };
    return <SignUpTeacherDialog isOpen={true} onClose={onTeacherDialogClose} />;
  }

  return (
    <SignUpDialogChoose
      isOpen={isSignUpOpen}
      onClose={hideSignUp}
      onTeacherSignUpClick={onTeacherSignUpClick}
    />
  );
};

export default SignUpDialog;
