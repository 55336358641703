import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { getPortalSignInUrl } from '../utils/urls';
import InternalNavLink from './internalNavLink';
import LogoLink from './logoLink';
import useSignUp from './signUpDialog/signUpHooks';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    transform: 'translateZ(0px)',
    zIndex: 100,
    transition:
      'background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 15,
      flexDirection: 'column',
    },
  },
  containerStatic: {
    backgroundImage: 'linear-gradient(180deg, #4D4E69 0%, #2C2E3B 99%)',
  },
  logInLink: {
    fontWeight: 500,
    textDecoration: 'none',
    textAlign: 'center',
    transition: 'opacity 0.2s, color 0.2s ease-in-out',
    '&:hover': {
      opacity: 0.6,
    },
  },
  buttonContainer: {
    width: '100%',
    maxWidth: '304px',
    minWidth: '280px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: 12,
    },
  },
  scrolled: {
    boxShadow: '0 2px 4px 0 rgb(0 0 0 / 20%);',
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
}));

interface Props {
  distanceFromTop?: number;
  style?: object;
  isStudent?: boolean;
  isSticky?: boolean;
}

const AppBar: React.FC<Props> = ({
  distanceFromTop = 0,
  style,
  isStudent = false,
  isSticky = true,
}: Props) => {
  const { showSignUp } = useSignUp();
  const classes = useStyles({});
  const isScrolled = distanceFromTop < -15;

  return (
    <header
      className={clsx(classes.container, {
        [classes.scrolled]: isScrolled && isSticky,
        [classes.containerStatic]: !isSticky,
      })}
      style={style}
    >
      <div>
        <LogoLink isStudent={isStudent} isColorDark={isScrolled && isSticky} />
      </div>
      <div className={classes.buttonContainer}>
        <InternalNavLink
          linkPath={'/for-educators/'}
          linkText={'FOR EDUCATORS'}
          isScrolled={isScrolled && isSticky}
        />
        <a
          href={getPortalSignInUrl()}
          className={classes.logInLink}
          style={{ color: isScrolled && isSticky ? 'black' : 'white' }}
        >
          LOG IN
        </a>
        <Button variant="contained" color="primary" onClick={showSignUp}>
          Sign Up
        </Button>
      </div>
    </header>
  );
};

export default AppBar;
