import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Link } from 'gatsby';
import React from 'react';

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    transition: 'opacity 0.2s',
    '&:hover': {
      opacity: 0.6,
    },
  },
  text: {
    fontSize: 20,
    color: theme.palette.common.white,
    transition: 'color 0.2s ease-in-out',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  textDark: {
    color: theme.palette.common.black,
  },
}));

interface Props {
  isColorDark?: boolean;
  isStudent?: boolean;
}

const LogoLink: React.FC<Props> = ({ isColorDark }: Props) => {
  const classes = useStyles();
  return (
    <Link to={'/'} className={classes.link}>
      <Typography
        variant={'h5'}
        className={clsx(classes.text, {
          [classes.textDark]: isColorDark,
        })}
      >
        FINDING FOCUS
      </Typography>
    </Link>
  );
};

export default LogoLink;
