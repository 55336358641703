import React from 'react';

import { getPortalUrl } from '../../utils/urls';
import { SignUpContext } from './signUpContext';

const SignUpDialog: React.FC = ({ children }) => {
  const [isSignUpOpen, setShowSignUp] = React.useState(false);
  const showSignUp = (): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    window.location = getPortalUrl(
      '/marketingRequestAnAccount?v2=true&showSignUpDialogRedirect=true'
    );
  };
  const hideSignUp = (): void => {
    setShowSignUp(false);
  };
  return (
    <SignUpContext.Provider
      value={{
        showSignUp,
        hideSignUp,
        isSignUpOpen,
      }}
    >
      {children}
    </SignUpContext.Provider>
  );
};

export default SignUpDialog;
