import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import React from 'react';

import { isEmailAddressFormatValid } from '../../utils/emails';
import SignUpSuccessDialog from './signUpSuccessDialog';

const API_URL = process.env.GATSBY_API_URL;

const StyledTextField = withStyles({
  root: {
    margin: 0,
  },
})(TextField);

const useStyles = makeStyles({
  content: {
    marginTop: 0,
  },
  label: {
    color: '#9B9B9B',
  },
});

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const SignUpTeacherDialog: React.FC<Props> = ({ isOpen, onClose }: Props) => {
  const classes = useStyles();

  const [hasAttemptedRequest, setHasAttemptedRequest] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [schoolEmail, setSchoolEmail] = React.useState('');
  const [school, setSchool] = React.useState('');
  const [jobTitle, setJobTitle] = React.useState('');

  const onChange = (setter: (arg: string) => void) => ({
    target: { value },
  }): void => {
    setHasAttemptedRequest(false);
    setter(value);
  };

  const isFieldError = (value: string): boolean => {
    return hasAttemptedRequest && value.length === 0;
  };

  const isValid = (): boolean => {
    return (
      firstName !== '' &&
      lastName !== '' &&
      school !== '' &&
      schoolEmail !== '' &&
      jobTitle !== '' &&
      isEmailAddressFormatValid(schoolEmail)
    );
  };

  const sendRequest = (): void => {
    setHasAttemptedRequest(true);
    if (!isValid() || isFetching) {
      return;
    }
    setIsFetching(true);
    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = (): void => {
      if (xhr.readyState === 4) {
        setIsFetching(false);
        if (xhr.status === 200) {
          setIsSuccess(true);
        } else {
          setError(true);
        }
      }
    };
    xhr.open('POST', `${API_URL}/lead`, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(
      JSON.stringify({
        firstName,
        lastName,
        schoolEmail,
        school,
        jobTitle,
      })
    );
  };

  const onKeyDown = (e: React.KeyboardEvent): void => {
    if (e.keyCode === 13 /* ENTER */) {
      sendRequest();
    }
  };

  if (isSuccess) {
    return <SignUpSuccessDialog onClose={onClose} isOpen={isOpen} />;
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle disableTypography>
        <Box textAlign="center">
          <Typography variant="h4" gutterBottom>
            Request a teacher account
          </Typography>
          <Typography variant="body1">
            High school teachers, counselors, and admins get free access.
          </Typography>
          {error && (
            <Typography variant="body1" color="error">
              Oops, there was an error, please try again.
            </Typography>
          )}
        </Box>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <StyledTextField
          autoFocus={true}
          label="First name"
          required={true}
          fullWidth={true}
          margin="normal"
          error={isFieldError(firstName)}
          value={firstName}
          onChange={onChange(setFirstName)}
          InputProps={{
            onKeyDown,
          }}
          InputLabelProps={{
            color: 'secondary',
          }}
        />
        <StyledTextField
          label="Last name"
          required={true}
          fullWidth={true}
          margin="normal"
          error={isFieldError(lastName)}
          value={lastName}
          onChange={onChange(setLastName)}
          InputProps={{
            onKeyDown,
          }}
          InputLabelProps={{
            color: 'secondary',
          }}
        />
        <StyledTextField
          label="School-provided email"
          required={true}
          fullWidth={true}
          margin="normal"
          error={
            isFieldError(schoolEmail) ||
            (hasAttemptedRequest && !isEmailAddressFormatValid(schoolEmail))
          }
          value={schoolEmail}
          onChange={onChange(setSchoolEmail)}
          InputProps={{
            onKeyDown,
          }}
          InputLabelProps={{
            color: 'secondary',
          }}
        />
        <StyledTextField
          label="Full school name (no acronyms)"
          required={true}
          fullWidth={true}
          margin="normal"
          error={isFieldError(school)}
          value={school}
          onChange={onChange(setSchool)}
          InputProps={{
            onKeyDown,
          }}
          InputLabelProps={{
            color: 'secondary',
          }}
        />
        <StyledTextField
          label="Job title"
          required={true}
          fullWidth={true}
          margin="normal"
          error={isFieldError(jobTitle)}
          value={jobTitle}
          onChange={onChange(setJobTitle)}
          InputProps={{
            onKeyDown,
          }}
          InputLabelProps={{
            color: 'secondary',
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={sendRequest}
          disabled={isFetching}
        >
          Request
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignUpTeacherDialog;
