import { red } from '@material-ui/core/colors';
import {
  createMuiTheme,
  // responsiveFontSizes,
  makeStyles,
} from '@material-ui/core/styles';

const BASE_FONT_SIZE = 16;

export const getRemFromPx = (fontSizePx: number): string =>
  `${(fontSizePx / BASE_FONT_SIZE).toFixed(4)}rem`;

// A custom theme for this app
const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      sizeLarge: {
        // h5
        fontSize: getRemFromPx(24),
        fontWeight: 600,
        letterSpacing: 0.57,
      },
    },
    MuiDivider: {
      root: {
        height: 3,
        backgroundColor: '#FDC72A',
      },
      middle: {
        width: '50%',
        '&$middle': {
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#FDC72A',
    },
    secondary: {
      main: '#4D4E69',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: '#2C2E3B',
      secondary: 'rgba(256, 256, 256, 1)',
      disabled: 'rgba(255, 255, 255, 0.38)',
      hint: 'rgba(255, 255, 255, 0.38)',
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'Arial', 'sans-serif'].join(','),
    h2: {
      fontSize: getRemFromPx(42),
      fontWeight: 600,
      letterSpacing: 1,
    },
    h3: {
      fontSize: getRemFromPx(32),
      fontWeight: 600,
      letterSpacing: 0.76,
    },
    h4: {
      fontSize: getRemFromPx(32),
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: getRemFromPx(36),
    },
    h5: {
      fontSize: getRemFromPx(24),
      fontWeight: 600,
      letterSpacing: 0.57,
    },
    subtitle1: {
      fontSize: getRemFromPx(24),
      fontWeight: 400,
      lineHeight: getRemFromPx(33),
      letterSpacing: 1.2,
    },
    subtitle2: {
      fontSize: getRemFromPx(22),
      fontWeight: 500,
      letterSpacing: 1,
    },
    body1: {
      fontSize: getRemFromPx(16),
      fontWeight: 500,
      letterSpacing: 0,
    },
    body2: {
      fontSize: getRemFromPx(14),
      fontWeight: 400,
      letterSpacing: 0,
    },
    button: {
      fontSize: getRemFromPx(14),
      fontWeight: 500,
      letterSpacing: 0.81,
    },
    overline: {
      fontSize: getRemFromPx(10),
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: getRemFromPx(16),
    },
  },
});

export const useHeaderStyles = makeStyles({
  bebas90: {
    fontFamily: 'Bebas Neue',
    fontSize: '90px',
    letterSpacing: 5,
    lineHeight: '90px',
    fontWeight: 700,
  },
  bebas84: {
    fontFamily: 'Bebas Neue',
    fontSize: '84px',
    letterSpacing: 2,
    lineHeight: '84px',
    fontWeight: 700,
  },
  bebas77: {
    fontFamily: 'Bebas Neue',
    fontSize: '77px',
    letterSpacing: 2,
    lineHeight: '77px',
    fontWeight: 700,
  },
  bebas75: {
    fontFamily: 'Bebas Neue',
    fontSize: '75px',
    letterSpacing: 1.78,
    lineHeight: '75px',
    fontWeight: 400,
  },
  bebas64: {
    fontFamily: 'Bebas Neue',
    fontSize: '64px',
    letterSpacing: 1.52,
    lineHeight: '64px',
    fontWeight: 700,
  },
  bebas48: {
    fontFamily: 'Bebas Neue',
    fontSize: '48px',
    letterSpacing: 1.33,
    lineHeight: '48px',
    fontWeight: 700,
  },
  bebas40: {
    fontFamily: 'Bebas Neue',
    fontSize: '40px',
    letterSpacing: 1.15,
    lineHeight: '40px',
    fontWeight: 700,
  },
  bebasBook40: {
    fontFamily: 'Bebas Neue Book',
    fontSize: '40px',
    letterSpacing: 0,
    lineHeight: '25px',
  },
});

export const useCommonStyles = makeStyles({
  flexCenterContents: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexRow: {
    flexDirection: 'row',
  },
  flexColumn: {
    flexDirection: 'column',
  },
  divider: {
    marginBottom: '35px !important', // important to override MUI theme styles
    marginTop: '35px !important', // important to override MUI theme styles
  },
  dividerLeft: {
    marginLeft: '0 !important', // important to override MUI theme styles
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto !important',
      marginBottom: '35px',
    },
  },
});

// theme = responsiveFontSizes(theme);

export default theme;
